<template>
  <div class="container kap-container">
    <DashboardNavigation ref="dashboardNavigation" @filters-changed="handleFiltersChanged"></DashboardNavigation>
    <div class="row flex-row-reverse mt-2">
      <div class="col-lg-6 col-md-12 mt-2">
        <div class="dashboard-view h-100">
          <div class="card h-100">
            <div class="card-body">
              <div class="title cursor-pointer m-3 company-color" v-on:click="openFunnelStagePage('Funded')" id="funded-card-title">Funded Amount</div>
              <div class="stats p-0 cursor-pointer company-color" v-on:click="openFunnelStagePage('Funded')">
                <div class="row">
                  <div class="col-md-12 text-right">
                    <div class="stats-value stats-value-dollar">{{ summary.totalFundedAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                  </div>
                </div>
              </div>
              <div class="list-group-item border-right-0 border-left-0 border-top-0 p-4"></div>
              <div class="title cursor-pointer m-3 company-color" id="commission-card-title">
                <span v-on:click="openFunnelStagePage('commissions')">Commission Accrued</span>
                <sup :class="['asterisk-icon']" data-toggle="modal" data-target="#commissionModal">
                  <font-awesome-icon :icon="['fas', 'asterisk']" />
                </sup>
              </div>
              <div class="stats p-0 cursor-pointer company-color mb-025-sm" v-on:click="openFunnelStagePage('commissions')">
                <div class="row">
                  <div class="col-md-12 text-right">
                    <div class="stats-value stats-value-dollar">{{ summary.totalCommission.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 mt-2">
        <div class="card content">
          <div class="list-group list-group-flush">
            <div v-for="(status, index) in menuItems" v-bind:key="index" v-on:click="openStatusPage(status.href)" class="cursor-pointer list-group-item list-group-items-statuses d-flex justify-content-between">
              {{ status.title }}
              <span class="badge badge-pill">{{ numberOfSubmissions[status.name] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommissionModal ref="commissionModal"></CommissionModal>
  </div>
</template>

<script>
import DashboardNavigation from "./DashboardNavigation";
import CommissionModal from "./Modal/CommissionModal";
import apiService from "@/plugins/api";
import {Dictionary} from "../constants/dictionary";

export default {
  name: 'DashboardComponent',
  computed: {},
  components: {
    DashboardNavigation,
    CommissionModal
  },
  data() {
    return {
      perPage: 100,
      submissions: {},
      summary: {
        totalCommission: 0,
        totalFundedAmount: 0
      },
      filterParams: '&a=1',
      menuItems: Dictionary.submission_statuses,
      numberOfSubmissions: {
        'All Submissions': 0,
        'Credit Review': 0,
        'Incomplete': 0,
        'Approved': 0,
        'Declined': 0,
        'Expired': 0,
        'Funded': 0,
      }
    };
  },
  methods: {
    handleFiltersChanged: async function (filters) {
      try {
        const resp = await apiService().getSubmissionsByCategory(
            {fromDate: filters.dateStart, toDate: filters.dateEnd},
            this.$store.state.auth.userData.custom_partner_uuid
        );
        this.numberOfSubmissions = resp.data.numberOfSubmissions;
        this.summary = resp.data.summary;
      } catch (e) {
        console.error(e);
      }
    },
    openStatusPage: function(path){
      this.$router.push(path + this.$refs.dashboardNavigation.getUrlFilterParameters());
    },
    openFunnelStagePage: function (pageName) {
      if (pageName === 'commissions') {
        this.$router.push(`/submissions?status=Funded&displayFundingOnly=true` + this.$refs.dashboardNavigation.getUrlFilterParameters());
        return;
      }
      this.$router.push(`/submissions?status=${pageName}` + this.$refs.dashboardNavigation.getUrlFilterParameters());
    },
  }
}
</script>
